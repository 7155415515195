.not_found_main {
  position: relative;
  height: 100vh;
  background: #030005;
}

.main_notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  text-align: center;
}

.main_notfound .notfound-404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}


.main_notfound .notfound-404 h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -12px;
  color: #030005;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #8400ff, 1px 1px 0 #2294b5;
  letter-spacing: -20px;
}

.main_notfound .notfound-404 h2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 150px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 2px 0 #8400ff;
  letter-spacing: 13px;
  margin: 0;
}


.main_notfound .notfound_home_btn {
  display: inline-block;
  text-transform: uppercase;
  color: #2294b5;
  text-decoration: none;
  border: 2px solid;
  background: 0 0;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: .2s all;
  transition: .2s all;
  margin-top: 50px;
  border-radius: 50px;
}