.contacts__page_main {
    display: flex;
    flex-direction: row;
    height: 90vh;
}

.contacts__page_left_main {
    width: 40%;
    height: 100%;

}

.contacts__page_right_main {
    width: 60%;
    background: #e1e5ee;
    height: 100%;

}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .contacts__page_main {
        display: flex;
        flex-direction: column;
    }

    .contacts__page_left_main {
        width: 100%;
    }
    .contacts__page_right_main {
        width: 100%;
    }

}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    .contacts__page_main {
        display: flex;
        flex-direction: column;

    }

    .contacts__page_left_main {
        width: 100%;

    }

    .contacts__page_right_main {
        width: 100%;
    }
}

/* For Small But Greater then tab */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .contacts__page_main {
        display: flex;
        flex-direction: column;

    }

    .contacts__page_left_main {
        width: 100%;

    }

    .contacts__page_right_main {
        width: 100%;
    }
}