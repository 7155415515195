/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: unset !important;
  background: black;
}
body {
  /* height: 100%; */
  /* background: radial-gradient( #1b2735 0%, #090a0f 100%); */
  /* overflow: hidden; */
  background: black;
}
.capeark-bg {
  background: black;
}
.capeark-color {
  color: #2294b5;
}


.required_star_error {
  color: red;
}