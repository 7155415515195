body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* fixed css  */
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes swing {
  20% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
  }

  40% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg);
  }

  60% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
  }

  80% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
  }

  100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
}


@keyframes slideleft {
  10% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    right: 0;
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  90% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    right: 100%;
  }
}


/***** Global Slide *****/
.slide-right,
.slide-left {
  width: 100%;
}

/***** Slide Right *****/
.slide-right {
  animation: 3s slide-right;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

/***** Slide Left *****/
.slide-left {
  animation: 3s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}



.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #2294b5;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #2294b5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}