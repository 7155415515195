
.top_user_informatiom_main {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top_user_informatiom_avatar {
    width: 65px;
    height: 65px;
    margin: auto;
    border-radius: 50%;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_user_informatiom_avatar > img {
    width: 100%;
    border-radius: 50%;
}

.top_user_informatiom_name {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}