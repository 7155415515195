.contact__card_main {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
}

.contact__card_main_cont {
    /* border: 1px solid rgb(255, 204, 0); */
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    font-size: 15px;
    margin-bottom: 15px;
}

.contact__card_main_info {
    font-size: 12px;
    text-align: end;
    color: gray;
}

.contact__card_main_name {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact__card_main_name div p span {
    font-weight: 700;
}

.contact__card_main_other_details p span {
    font-weight: 700;
}

.contact__card_main_message_cont span {
    font-weight: 700;
}

.contact__card_main_message_cont p {
    margin-top: 10px;
}

.contact__card_main_action_cont {
    text-align: end;

}

.contact__card_edit_btn {
    background: #2294b5 !important;
    color: #fff;
    border: none !important;
}
.contact__card_edit_btn:focus {
    box-shadow: none !important;
}

.contact__card_delete_btn {
    background: #b52222 !important;
    color: #fff;
    border: none !important;
}
.contact__card_delete_btn:focus {
    box-shadow: none !important;
}