.auth__main_cont {
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.auth__main_left {
    width: 50%;
    background: #e1e5ee;

}

.auth__main_logo_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.auth__main_logo_div {
    max-width: 300px;
}

.auth__main_logo_div img {
    width: 100%;
    height: auto;
}

.auth__main_left_blur_cont {
    background: rgba(253, 253, 253, 0);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 20px;
    width: 100%;
    height: 200px;
    margin-top: -125px;
}


.auth__main_right {
    width: 50%;

}


.auth__right_action_top {
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;

}

.auth__right_action_top div:nth-child(1) {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: black;
}

.auth__right_action_top div:nth-child(2) {
    width: 120px;
}

.auth__right_action_top div img {
    width: 100%;
}

.auth__right_action_middle {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    
}
.auth__right_action_middle p{
    color: gray;
    font-size: 15px;
}

.auth__right_action_middle_form {
    width: 60%;
}

.auth_login_input_lebal {
    font-size: 15px;
    font-weight: 600;
}

.auth_login_feedback_text {
    font-size: 12px !important;
}


.auth_login_input_box {
    border-radius: 5px !important;

}

.auth_forget_pass_text {
    font-size: 13px;
    color: #2294b5;
    text-decoration: none;
    float: right;
    font-weight: 700;
}

.auth_forget_pass_text:hover {
    color: #2294b5;
    text-decoration: none;
}

.auth_login_input_box:hover {
    border-color: black !important;
}

.auth_login_input_box:focus {
    box-shadow: none !important;
}

.auth_button_main {
    width: 100%;
    background: #2294b5 !important;
    color: #fff;
    border: none !important;
}

.auth_button_main:focus {
    box-shadow: none !important;
}

.auth_redirect_signup_text {
    text-align: center;
    font-size: 15px;
    color: gray;
    font-weight: 500;
}

.auth_redirect_signup_text a {
    font-size: 14px;
    color: #2294b5;
    text-decoration: none;
    font-weight: 700;
}

.auth__right_action_bottom {
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    font-size: 13px;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .auth__main_left {
        display: none;
    }

    .auth__main_right {
        width: 100%;
    }

    .auth__right_action_middle_form {
        width: 90%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    .auth__main_left {
        display: none;
    }

    .auth__main_right {
        width: 100%;
    }

    .auth__right_action_middle_form {
        width: 90%;
    }
}

/* For Small But Greater then tab */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .auth__main_right {
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .auth__main_right {
        width: 100%;
    }
}