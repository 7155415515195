.main_loader_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 20px;
}

.main_loader_cont_circle {
    color: #0E374B !important;
    
}


#main_loader_cont_dot .ant-spin-dot-item {
    background-color: #0E374B !important; 
}