.home_main_cont {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e1e5ee;

}
.home_main_cont div {
    max-width: 250px;

}
.home_main_cont div img {
    width: 100%;
    height: auto;
}